<template>
    <div>
        <div class="right-menu shipping-right">

            <div class="db_top pt-4">
                <div class="db-container">

                    <div class="main-search-order">
                        <div class="col-md-12">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                        
                                    <div class="tabsBtnRow">
                                        <button style="display: flex; align-items: center; justify-content: center; gap: 5px;" @click.prevent="exitEditMode()" class="mr-2">
                                            <svg style="width:20px; height:20px;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3zm2-7h7v2H6v3l-5-4 5-4v3z"/>
                                                </g>
                                            </svg>
                                            Exit Edit Mode
                                        </button>
                                    </div>
                                        
                                </div>
                                <div class="col-md-6">
                                    <div class="addNewOrderBtn text-right">

                                        <div class="tabsBtnRow">
                                            <button :class="currentDevice.name == 'Desktop' ? 'tab_active' : '' " @click.prevent="changeDeviceSize('desktop')">
                                                <svg :style="`fill:${currentDevice.name == 'Desktop' ? '#1ec2c2' : '#000'}; height:20px;width:70px;`" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
                                                    <title/>
                                                    <path d="M90,0H6A5.9966,5.9966,0,0,0,0,6V66a5.9966,5.9966,0,0,0,6,6H42V84H30a6,6,0,0,0,0,12H66a6,6,0,0,0,0-12H54V72H90a5.9966,5.9966,0,0,0,6-6V6A5.9966,5.9966,0,0,0,90,0ZM84,60H12V12H84Z"/>
                                                </svg>
                                                Desktop
                                            </button>
                                            <button :class="currentDevice.name == 'Laptop' ? 'tab_active' : '' " @click.prevent="changeDeviceSize('laptop')">
                                                <svg :style="`fill:${currentDevice.name == 'Laptop' ? '#1ec2c2' : '#000'}; height:20px;width:20px;`" height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0h48v48h-48z" fill="none"/>
                                                    <path d="M40 36c2.21 0 3.98-1.79 3.98-4l.02-22c0-2.21-1.79-4-4-4h-32c-2.21 0-4 1.79-4 4v22c0 2.21 1.79 4 4 4h-8c0 2.21 1.79 4 4 4h40c2.21 0 4-1.79 4-4h-8zm-32-26h32v22h-32v-22zm16 28c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
                                                </svg>
                                                Laptop
                                            </button>
                                            <button :class="currentDevice.name == 'Mobile' ? 'tab_active' : '' " @click.prevent="changeDeviceSize('mobile')">
                                                <svg :style="`fill:${currentDevice.name == 'Mobile' ? '#1ec2c2' : '#000'}; height:20px;width:20px;`" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <title/>
                                                    <path d="M17,0H7A3,3,0,0,0,4,3V21a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V3A3,3,0,0,0,17,0ZM6,19V3.5A.5.5,0,0,1,6.5,3h11a.5.5,0,0,1,.5.5V19a.5.5,0,0,1-.5.5H6.5A.5.5,0,0,1,6,19Z"/>
                                                </svg>
                                                Mobile
                                            </button>
                                            <button :class="currentDevice.name == 'iPad' ? 'tab_active' : '' " @click.prevent="changeDeviceSize('ipad')">
                                                <svg :style="`fill:${currentDevice.name == 'iPad' ? '#1ec2c2' : '#000'}; height:20px;width:20px;`" height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0h48v48h-48z" fill="none"/>
                                                    <path d="M42 8h-36c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h36c2.21 0 3.98-1.79 3.98-4l.02-24c0-2.21-1.79-4-4-4zm-4 28h-28v-24h28v24z"/>
                                                </svg> 
                                                IPad
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tabsMainSection">

                        <div class="tabsContentSection">

                            <div class="tabPageSection">
                                <div class="siteBox">
                                    <div class="siteViewBox" id="siteView">
                                        <iframe 
                                            class="siteFrame"
                                            ref="siteView" 
                                            :src="siteViewUrl" 
                                            :style="{ width: `${currentDevice.width}px`, height: `${currentDevice.height}px` }"
                                            frameborder="0"
                                        >
                                        </iframe>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import _ from "lodash";
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from "vuex";

export default {
    metaInfo() {
        return {
            title: 'Theme Management | Edit Theme | YeetCommerce',
        };
    },
    data() {
        return {
            token:'',
            tokenDetails:{},

            currentDevice: {  
                name: "Desktop",
                width: 1920,
                height: 1080,
                bodyClass: "desktop-device", 
            },
        }
    },
    components: {
        subHeader,
    },
    computed: {
        ...mapGetters({
            settings: "settings_module/settings",
            loadingSettings: "settings_module/isPending",
        }),
        siteViewUrl(){

            if(this.settings){

                if(this.settings.secondary_domain){

                    return 'https://' + this.settings.secondary_domain + "?editMode=1&token=" + this.token

                }else{

                    return 'https://' + this.settings.domain + "?editMode=1&token=" + this.token

                }

            }else{

                return ""

            }

        },  
    },
    watch: {

    },
    methods:{
        async exitEditMode(){

            MessageBox.confirm(
                "Are you sure you want to exit from the edit mode?", 
                "Warning", 
                {
                    type: "warning",
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                }
            ).then(async () => {

                let formData = new FormData();

                formData.append('token',this.token);

                let loader = Loading.service({
                    text: "Exiting theme customization session. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/theme-customization/token/revoke',formData);
                    if(res.data.status_code == "1823"){

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: "Your theme customization session has ended. Come back anytime to modify your theme.",
                        });

                        if(window.innerWidth >= 1024){

                            var element = document.getElementsByTagName("body")[0];

                            element.classList.remove("hideSideBar");
                            element.classList.add("activeIpad");

                        }

                        this.$router.push({name:'Theme Customization'});

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.token){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.error.token[0],
                            });

                        }else if(error.response.data.error.includes("deleted or isn't available")){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.message,
                        });

                    }

                    this.$router.push({name:'Theme Customization'});

                }finally{

                    loader.close();

                }

            }).catch((error) => {})

        },

        changeDeviceSize(device){

            if(device == 'desktop'){

                this.currentDevice = {
                    name: "Desktop",
                    width: 1920,
                    height: 1080,
                }

            }else if(device == 'laptop'){

                this.currentDevice = {
                    name: "Laptop",
                    width: 1366,
                    height: 768,
                }

            }else if(device == 'ipad'){

                this.currentDevice = {
                    name: "iPad",
                    width: 768,
                    height: 1024,
                }

            }else if(device == 'mobile'){

                this.currentDevice = {
                    name: "Mobile",
                    width: 430,
                    height: 667,
                }

            }

        },

        async verifyToken(){

            let formData = new FormData();

            formData.append('token',this.token);

            let loader = Loading.service({
                text: "Your session is being verified. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/theme-customization/token/verify',formData);
                if(res.data.status_code == "1822"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: "Welcome! Modify your theme to suit your preferences.",
                    });

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.token){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error.token[0],
                        });

                    }else if(error.response.data.error.includes('with your account') ||
                    error.response.data.error.includes('timed out.') ||
                    error.response.data.error.includes("deleted or isn't available.")){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

                this.$router.push({name:'Theme Customization'});

            }finally{

                loader.close();

            }

        },
    },
    mounted(){

        if(!this.$route.params.token_details){

            this.token = this.$route.params.token;

            this.verifyToken();

        }else{

            this.tokenDetails = this.$route.params.token_details;

            this.token = this.$route.params.token;

            this.verifyToken();

        }

        if((this.settings.length == 0 || !this.settings)){

            this.$store.dispatch('settings_module/fetchStoreSetting');

        }

    },
    beforeMount(){

    },
    beforeDestroy(){

    }
}
</script>

<style scoped>
.siteViewBox .siteFrame{
    width: 100%;
    height: 100%;
    max-width: 100%;
}
.siteViewBox{
    margin-top: 20px;
    height: 900px;
    display: flex;
    justify-content: center;
}
.addNewOrderBtn button{
    display: flex;  
    align-items: center;
}
@media (max-width: 1024px) {
    .addNewOrderBtn{
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 991px) {
    .addNewOrderBtn{
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 767px) {
    .addNewOrderBtn{
        display: flex;
        justify-content: center !important;
        text-align: center !important; 
    }
    .addNewOrderBtn.text-right{
        display: none;
    }
    .siteViewBox iframe{
        width: 100%;
        height: 100%;
    }
    .tabsBtnRow{
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 576px) {
    .addNewOrderBtn{
        display: flex;
        justify-content: center !important;
        text-align: center !important; 
    }
}
</style>